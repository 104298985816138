import React, { useEffect, useState } from 'react';
import { getNotificationAssignedAt } from '../../../../services/userService';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Box, Typography } from '@mui/material';
import { differenceInDays, addDays, isAfter } from 'date-fns';
import { useTranslation } from 'react-i18next';

const SurveyProgress = ({ userId }) => {
    const [daysCompleted, setDaysCompleted] = useState(0);
    const [totalSurveyDays, setTotalSurveyDays] = useState(0);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        const calculateProgress = async () => {
            try {
                const data = await getNotificationAssignedAt(userId);

                if (data.length === 0) {
                    throw new Error('No notifications assigned.');
                }

                let completedDays = 0;
                const currentDate = new Date();
                let totalDays = 0;

                data.forEach((notificationDate) => {
                    const startDate = new Date(notificationDate);
                    const endDate = addDays(startDate, 14); // Each period lasts 14 days

                    if (isAfter(currentDate, endDate)) {
                        // If the current date is after the end of this notification period, consider all 14 days as completed
                        completedDays += 14;
                    } else {
                        // Otherwise, calculate the days completed in the current ongoing period
                        const daysInPeriod = differenceInDays(currentDate, startDate);
                        completedDays += daysInPeriod;
                    }

                    // Increase total survey days by 14 for each notification period
                    totalDays += 14;
                });

                setDaysCompleted(completedDays); // Set total days completed
                setTotalSurveyDays(totalDays); // Set the total survey days
                setLoading(false);
            } catch (error) {
                console.error('Error calculating survey progress:', error);
                setLoading(false);
            }
        };

        calculateProgress();
    }, [userId]);

    if (loading) {
        return <Typography>{t('surveyProgress.loading')}</Typography>;
    }

    const percentage = (daysCompleted / totalSurveyDays) * 100;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, padding: 4 }}>
            <Box sx={{ width: 100, height: 100 }}>
                <CircularProgressbar
                    value={percentage}
                    text={`${daysCompleted}/${totalSurveyDays}`}
                    styles={buildStyles({
                        textSize: '16px',
                        textColor: '#3e98c7',
                        pathColor: daysCompleted === totalSurveyDays ? '#4caf50' : '#3e98c7',
                        trailColor: '#d6d6d6',
                    })}
                />
            </Box>
            <Typography variant="h6">
                {t('surveyProgress.daysCompleted', { daysCompleted, totalSurveyDays })}
            </Typography>
            <Typography variant="body1">
                {daysCompleted === totalSurveyDays
                    ? t('surveyProgress.congratulations')
                    : t('surveyProgress.daysLeft', { daysLeft: totalSurveyDays - daysCompleted })}
            </Typography>
        </Box>
    );
};

export default SurveyProgress;
