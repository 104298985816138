import React, { useState, useEffect } from 'react';
import { startOfWeek, endOfWeek } from 'date-fns';
import { Box, Card, CardContent, Grid, Typography, Button } from '@mui/material';
import SurveyProgress from '../SurveyProgress/SurveyProgress';
import ToDoList from '../ToDoList/ToDoList';
import DailySurveyChart from '../DailySurveyChart';
import PerformanceChart from '../PerformanceChart';
import PerformanceChecklist from '../PerformanceCheckList/PerformanceCheckList';
import './Overview.css';
import { useTranslation } from 'react-i18next';

const Overview = ({ userId, setContent, dailySurveyResponses = [], performanceResponses = [], noData, isAdmin, refreshData }) => {
    const [currentWeek, setCurrentWeek] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }));
    const { t } = useTranslation();

    // Refresh state to trigger a re-render
    const [refresh, setRefresh] = useState(0);
    useEffect(() => {
        // Calculate the time until the next whole hour
        const now = new Date();
        const minutesUntilNextHour = 60 - now.getMinutes();
        const millisecondsUntilNextHour = minutesUntilNextHour * 60 * 1000;

        // Set a timeout to refresh at the next whole hour
        const timeoutId = setTimeout(() => {
            setRefresh(prev => prev + 1);

            // Set an interval to refresh every hour after the initial refresh
            const intervalId = setInterval(() => {
                setRefresh(prev => prev + 1);
            }, 60 * 60 * 1000); // 60 minutes * 60 seconds * 1000 milliseconds = 1 hour

            // Cleanup interval on component unmount
            return () => clearInterval(intervalId);
        }, millisecondsUntilNextHour);

        // Cleanup timeout on component unmount
        return () => clearTimeout(timeoutId);
    }, []);

    // Filter responses for the current week
    const weekStart = startOfWeek(currentWeek, { weekStartsOn: 1 });
    const weekEnd = endOfWeek(currentWeek, { weekStartsOn: 1 });
    const currentWeekResponses = performanceResponses.filter(response => {
        const responseDate = new Date(response.timestamp);
        return responseDate >= weekStart && responseDate <= weekEnd;
    });

    return (
        <Box className="overview-container">
            <Typography variant="h4" className="overview-title" gutterBottom>
                {t('overview.dashboardOverview')}
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={refreshData}
                style={{ marginBottom: '20px' }}
            >
                {t('overview.refresh')}
            </Button>
            <Grid container className="grid-container" spacing={4}>
                {/* First Column: Survey Progress and ToDo List */}
                <Grid item xs={12} md={4} className="grid-item">
                    <Card elevation={3} className="card">
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                {t('overview.surveyProgress')}
                            </Typography>
                            <SurveyProgress userId={userId} key={refresh} />
                        </CardContent>
                    </Card>

                    <Card elevation={3} className="card">
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                {t('overview.toDoList')}
                            </Typography>
                            <ToDoList
                                userId={userId}
                                setContent={setContent}
                                isAdmin={isAdmin}
                                key={refresh}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4} className="grid-item">
                    <Card elevation={3} className="card">
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                {t('overview.dailySurveyChart')}
                            </Typography>
                            <DailySurveyChart responses={dailySurveyResponses} key={refresh} />
                        </CardContent>
                    </Card>
                </Grid>

                {/* Third Column: Performance Chart and Checklist */}
                <Grid item xs={12} md={4} className="grid-item">
                    <Card elevation={3} className="card">
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                {t('overview.performanceChart')}
                            </Typography>
                            <PerformanceChart
                                responses={currentWeekResponses}
                                currentWeek={currentWeek}
                                setCurrentWeek={setCurrentWeek}
                                key={refresh}
                            />
                        </CardContent>
                    </Card>

                    <Card elevation={3} className="card" style={{ marginTop: '20px' }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                {t('overview.performanceChecklist')}
                            </Typography>
                            <PerformanceChecklist userId={userId} key={refresh} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Overview;