import React, { useState, useEffect } from 'react';
import { Box, List, ListItem, ListItemText, Typography, Button, Grid } from '@mui/material';
import { getPerformanceResponses } from '../../../../services/performanceService';
import { format, isSameDay } from 'date-fns';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';

const PerformanceChecklist = ({ userId }) => {
    const [performanceResponses, setPerformanceResponses] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchPerformanceData = async () => {
            try {
                const response = await getPerformanceResponses(userId);
                setPerformanceResponses(response.data || []);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching performance responses:', error);
                setPerformanceResponses([]);
                setLoading(false);
            }
        };

        fetchPerformanceData();
    }, [userId, currentDate]);

    const getHourRange = (hour) => {
        const start = new Date(currentDate);
        start.setHours(hour, 0, 0, 0);
        const end = new Date(start);
        end.setHours(hour + 1, 0, 0, 0);
        return { start, end };
    };

    const isHourCompleted = (hour) => {
        const { start, end } = getHourRange(hour);
        return performanceResponses.some(response => {
            const responseTime = new Date(response.timestamp);
            return responseTime >= start && responseTime < end;
        });
    };

    const allHoursCompleted = () => {
        const hours = Array.from({ length: 10 }, (_, i) => i + 8); // Hours from 8 AM to 5 PM
        return hours.every(hour => isHourCompleted(hour));
    };

    const handlePrevDay = () => {
        let newDate = new Date(currentDate);
        do {
            newDate.setDate(newDate.getDate() - 1);
        } while (newDate.getDay() === 6 || newDate.getDay() === 0); // Skip Saturday (6) and Sunday (0)
        setCurrentDate(newDate);
    };

    const handleNextDay = () => {
        let newDate = new Date(currentDate);
        do {
            newDate.setDate(newDate.getDate() + 1);
        } while (newDate.getDay() === 6 || newDate.getDay() === 0); // Skip Saturday (6) and Sunday (0)
        setCurrentDate(newDate);
    };

    const renderChecklist = () => {
        const hours = Array.from({ length: 10 }, (_, i) => i + 8); // Hours from 8 AM to 5 PM

        return (
            <Grid container spacing={2}>
                {hours.map(hour => (
                    <Grid item xs={12} sm={4} key={hour}>
                        <ListItem sx={{ justifyContent: 'center' }}>
                            <ListItemText
                                primary={`${hour}:00`}  // Only display the start time
                                style={{
                                    textDecoration: isHourCompleted(hour) ? 'line-through' : 'none',
                                    textAlign: 'center',
                                }}
                            />
                        </ListItem>
                    </Grid>
                ))}
            </Grid>
        );
    };

    if (loading) {
        return <Typography>{t('performanceChecklist.loading')}</Typography>;
    }

    const today = new Date();
    const allCompleted = allHoursCompleted();

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h6" gutterBottom>
                {t('performanceChecklist.checklistTitle', { date: format(currentDate, 'EEEE, MMMM d, yyyy') })}
                {allCompleted && <CheckCircleIcon color="success" sx={{ marginLeft: 1 }} />}
            </Typography>
            <List>
                {renderChecklist()}
            </List>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                <Button variant="contained" onClick={handlePrevDay}>{t('performanceChecklist.previousDay')}</Button>
                <Button
                    variant="contained"
                    onClick={handleNextDay}
                    disabled={isSameDay(currentDate, today)}
                >
                    {t('performanceChecklist.nextDay')}
                </Button>
            </Box>
        </Box>
    );
};

export default PerformanceChecklist;
